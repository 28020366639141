<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title class="title">
            Voucher Types
            <v-spacer></v-spacer>
            <!-- New Voucher Type has been disabled temporarity -->
            <!-- <add-button
              permission="subject-read"
              @action="(form.dialog = true), addData(), (updateStatus = false)"
            >
              New Voucher Type
            </add-button> -->
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :pagination.sync="pagination"
            :total-items="form.items.meta.total"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.index + form.items.meta.from }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.code }}</td>
              <td class="text-xs-left">
                {{ props.item.pr_type.capitalizeFirstLetter() }}
              </td>
              <td class="text-xs-left">
                {{
                  props.item.setting.voucher_numbering.capitalizeFirstLetter()
                }}
              </td>
              <td class="text-xs-left">
                {{ props.item.setting.start_number }}
              </td>
              <td class="text-xs-left">
                {{ props.item.setting.renumbering.capitalizeFirstLetter() }}
              </td>
              <td class="text-xs-right">
                <edit-button
                  permission="subject-read"
                  @agree="form.edit(props.item), editData(props.item)"
                  v-if="props.item.company_id"
                />
                <delete-button
                  permission="subject-read"
                  @agree="form.delete(props.item.id)"
                  v-if="props.item.company_id"
                />
              </td>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <!--<v-flex xs12 sm4>-->
      <!--<v-card>-->
      <!--<v-card-title class="title">-->
      <!--<v-spacer/>-->
      <!--<v-btn icon ma-0 small @click="setChart()">-->
      <!--<v-icon small>autorenew</v-icon>-->
      <!--</v-btn>-->
      <!--</v-card-title>-->
      <!--<apexchart v-if="chartData.length" type="pie" :options="chartOptions" :series="chartData"/>-->
      <!--</v-card>-->
      <!--</v-flex>-->
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-layout row wrap>
                <v-flex xs6 sm6>
                  <v-text-field
                    label="Name*"
                    autocomplete="off"
                    required
                    class="pa-2"
                    v-model="form.name"
                    name="name"
                    :error-messages="form.errors.get('name')"
                  />
                </v-flex>
                <v-flex xs6 sm6>
                  <v-text-field
                    label="Prefix*"
                    autocomplete="off"
                    required
                    class="pa-2"
                    v-model="form.code"
                    :disabled="updateStatus"
                    name="code"
                    :error-messages="form.errors.get('code')"
                    id="code"
                  />
                </v-flex>
                <v-flex xs6 sm6>
                  <v-text-field
                    label="Description"
                    autocomplete="off"
                    required
                    class="pa-2"
                    v-model="form.description"
                    name="description"
                    :error-messages="form.errors.get('description')"
                    id="description"
                  />
                </v-flex>
                <v-flex xs6 sm6>
                  <v-select
                    :items="prTypes"
                    required
                    class="pa-2"
                    label="Entry Type*"
                    v-model="form.pr_type"
                    :error-messages="form.errors.get('pr_type')"
                    name="pr_type"
                  />
                </v-flex>
                <v-flex xs6 sm6>
                  <span class="voucher">Voucher Numbering</span>

                  <v-radio-group
                    v-model="setting.voucher_numbering"
                    class="pa-2"
                    row
                  >
                    <v-radio label="Automatic" value="automatic"></v-radio>
                    <v-radio label="Manual" value="manual"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs6 sm6 v-if="voucherNumberingSetting">
                  <v-text-field
                    label="Start Numbering"
                    autocomplete="off"
                    required
                    class="pa-2"
                    v-model="setting.start_number"
                    name="start_number"
                    type="number"
                    :error-messages="form.errors.get('start_number')"
                    id="start_number"
                    :disabled="updateStatus"
                  />
                </v-flex>
                <v-flex xs6 sm6 v-if="voucherNumberingSetting">
                  <v-select
                    :items="renumberingType"
                    required
                    class="pa-0"
                    label="Renumbering"
                    v-model="setting.renumbering"
                    :error-messages="form.errors.get('renumbering_type')"
                    name="renumbering_type"
                    :disabled="updateStatus"
                  />
                </v-flex>
              </v-layout>
              <small>*indicates required field</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outline
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="warning" outline @click="store(true)"
              >Save And Continue</v-btn
            >
            <v-btn color="success" outline @click="store(false)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        code: "",
        description: "",
        pr_type: "journal",
        setting: {},
      },
      "/api/journal-type"
    ),
    search: null,
    pagination: {
      rowsPerPage: 10,
    },
    setting: {
      voucher_numbering: "automatic",
      start_number: 1,
      renumbering: "yearly",
    },
    updateStatus: false,
    voucherNumberingSetting: true,
    headers: [
      { text: "SN", align: "center", value: "id", width: 50 },
      { text: "Name", align: "left", value: "name" },
      { text: "Prefix", align: "left", value: "code", sortable: false },
      { text: "Entry Type", align: "left", value: "pr_type", sortable: false },
      {
        text: "Voucher Numbering",
        align: "left",
        value: "voucher_numbering",
        sortable: false,
      },
      {
        text: "Start Number",
        align: "left",
        value: "start_number",
        sortable: false,
      },
      {
        text: "Renumbering",
        align: "left",
        value: "renumbering",
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    prTypes: [
      { text: "Journal", value: "journal" },
      { text: "Payment", value: "payment" },
      { text: "Receipt", value: "receipt" },
      { text: "Purchase", value: "purchase" },
      { text: "Sales", value: "sales" },
    ],
    renumberingType: [
      {
        text: "Monthly",
        value: "monthly",
      },
      {
        text: "Yearly",
        value: "yearly",
      },
    ],
    chartData: [],
    chartOptions: {
      title: {
        text: "Journals",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    "setting.voucher_numbering": function(v) {
      if (v == "manual") {
        this.voucherNumberingSetting = false;
      } else {
        this.voucherNumberingSetting = true;
      }
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&")
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        // this.setChart(data.data);
      });

      /*this.$rest.post('/api/asdkfasdf', data).then(response => {
                    // success..
                }).catch(error => {
                    // error...
                }).finally(() => {
                    //
                })*/
    },
    editData(item) {
      this.updateStatus = true;
      this.setting = item.setting;
    },
    addData() {
      this.setting.voucher_numbering = "automatic";
      this.setting.start_number = 1;
      this.setting.renumbering = "yearly";
    },

    store(tag) {
      this.form.closeModal = !tag;
      this.form.setting = this.setting;
      this.form
        .store()
        .then((data) => {
          this.setting.voucher_numbering = "automatic";
          this.setting.start_number = 1;
          this.setting.renumbering = "yearly";
        })
        .catch((e) => {
          this.$events.fire("notfication", {
            message: e.message,
            status: "error",
          });
          // this.form.dialog = false;
        });
    },

    // setChart(data) {
    //     if (data == null) {
    //         data = this.form.items.data
    //     }
    //
    //     let labels = data.map(item => {
    //         return item.name
    //     })
    //
    //     this.chartOptions.labels = labels;
    //
    //     this.chartData = data.map(item => {
    //         return Math.floor(Math.random() * Math.floor(50));
    //     })
    // }
  },
};
</script>
<style lang="scss" scoped>
.voucher {
  font-size: 12px;
  margin-top: -8px;
  /* margin-bottom: 5px; */
  position: absolute;
  color: gray;
}
</style>
